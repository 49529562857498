
.checkout-product-items {
    display: flex;
    flex-direction: column;
    gap: 14px

    .checkout-product-item {
        display flex
        align-items center
        flex-wrap wrap
        gap 0 14px

        &.main-product {
            .checkout-product-item-image {
                position relative
                width 100%
                height auto
                max-height 390px
                min-height 390px
                flex-basis 100%
                border-radius 5px
                border none
                margin 0 auto
                aspect-ratio 1 / 1

                .image {
                    width 100%
                    height 100%

                    object-fit contain
                    object-position center
                    border-radius 5px
                }
            }

            .product-name {
                font-size 20px
                font-weight 600
            }
        }
    }



    .checkout-product-item-image {
        position relative
        width 64px
        height 64px
        border 1px solid rgb(214, 214, 214)
        border-radius 5px

        .image {
            width 100%
            height 100%

            object-fit contain
            object-position center
            border-radius 5px
        }
    }


    .product-name {
        position relative
        box-sizing border-box
        display block
        flex: 1
        min-height 21px
        margin-bottom 0px
        margin-left 0px
        margin-right 0px
        margin-top 0px
        width 284.195px
        text-size-adjust 100%
        font-size 14px
        font-weight 400
        letter-spacing normal
        line-height 21px
        text-transform none
        word-break break-word
        color rgb(0, 0, 0)
        margin-block-end 0px
        margin-block-start 0px
        margin-inline-end 0px
        margin-inline-start 0px
        overflow-wrap anywhere
        unicode-bidi isolate
    }

    .price {
        box-sizing border-box
        text-size-adjust 100%
        font-size 14px
        font-weight 400
        letter-spacing normal
        line-height 21px
        text-transform none
        text-wrap nowrap
        white-space-collapse preserve
    }

    .badge-container {
        position absolute
        top 0px
        right 0px
        transform: translate(25%, -50%);
    }

    .badge {
        align-items center
        box-sizing border-box
        display inline-flex
        height 22px
        justify-content center
        min-block-size 22px
        min-height 22px
        min-inline-size 22px
        min-width 22px
        padding-left 7px
        padding-right 7px
        width 22px
        text-size-adjust 100%
        font-size 12px
        font-weight 700
        letter-spacing normal
        line-height 18px
        text-transform none
        color rgb(245, 245, 245)
        background-color rgb(102, 102, 102)
        -webkit-font-smoothing subpixel-antialiased
        border-bottom-left-radius 36px
        border-bottom-right-radius 36px
        border-top-left-radius 36px
        border-top-right-radius 36px
        overflow-wrap break-word
        padding-inline-end 7px
        padding-inline-start 7px
        unicode-bidi isolate
    }

    .btn-remove {
        cursor pointer
        color rgb(0, 113, 227)
    }
}
